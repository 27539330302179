<!-- 审核 -->
<template>
  <div class="diseaseList">
    <!-- 封装selectForm筛选 -->
    <el-form :inline="true">
      <el-button
        class="addBtn"
        type="primary"
        size="small"
        v-waves
        @click="handleAdd"
        >新增</el-button
      >
      <el-form-item label="所属门店:" style="margin:0 20px" size="small">
        <el-select
          v-model="listQuery.store_id"
          placeholder="请选择"
          clearable
          multiple
          collapse-tags
        >
          <div v-for="(item, index) in filterStoreList" :key="index">
            <el-option
              :label="item.store_name"
              :value="item.store_id + ''"
            ></el-option>
          </div>
        </el-select>
      </el-form-item>
      <el-form-item label="会员姓名: " style="margin-right:20px" size="small">
        <el-input
          v-model="listQuery.user_name"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号: " style="margin-right:20px" size="small">
        <el-input
          v-model="listQuery.user_phone"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-button
        class="addBtn"
        type="primary"
        @click="getListData"
        size="small"
        v-waves
        >搜索</el-button
      >
    </el-form>

    <div class="tableList">
      <!-- 封装表单table组件 -->
      <ElTable
        :columns="listHeader"
        :tableData="tableData"
        :loading="loading"
        :total="listQuery.total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
      >
        <template slot="store_id" slot-scope="{ row }">
          <StoreToString :store_id="String(row.store_id)" />
        </template>
        <template slot="type" slot-scope="{ row }">
          <el-tag
            v-if="row.type === '储值卡'"
            type="success"
            effect="dark"
            size="mini"
          >
            储值卡
          </el-tag>
          <el-tag v-else-if="row.type === '积分卡'" effect="dark" size="mini">
            积分卡
          </el-tag>
          <el-tag v-else type="danger" effect="dark" size="mini">
            次卡
          </el-tag>
        </template>
        <!-- <template slot="state" slot-scope="scope">
           <FillingStatus :value="scope.row" />
        </template>
        <template slot="fillRate" slot-scope="scope">
          <Percentage :value="scope.row"></Percentage>
        </template> -->
        <template slot="operation" slot-scope="{ row }">
          <div class="operation">
            <el-button
              size="mini"
              style="color: #afb86d"
              type="text"
              @click="handleEdit(row, 1)"
              primary
              >编辑</el-button
            >
            <el-button
              size="mini"
              style="color: #afb86d"
              type="text"
              @click="handleEdit(row, 2)"
              primary
              >充值</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="handleEdit(row, 3)"
              style="color: #afb86d"
              >消费</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: rgb(255, 0, 0);"
              @click="handleDelete(row)"
              >删除</el-button
            >
          </div>
        </template>
      </ElTable>

      <!-- 新增修改会员表单 -->
      <el-dialog
        :title="dialogType == 0 ? '创建会员' : '编辑会员'"
        :visible.sync="dialogFormVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="dialogForm" label-width="90px">
          <el-form-item label="所属门店:" required>
            <el-select
              v-model="dialogForm.store_id"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <div v-for="(item, index) in filterStoreList" :key="index">
                <el-option
                  :label="item.store_name"
                  :value="item.store_id"
                ></el-option>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="卡种类型: " required>
            <el-select
              :disabled="dialogType == 1"
              v-model="dialogForm.type"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <el-option
                v-for="item in vipCardList"
                :label="item.name"
                :value="item.name"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="会员姓名" required>
            <el-input v-model="dialogForm.user_name" clearable></el-input>
          </el-form-item>

          <el-form-item label="手机号" required>
            <el-input
              v-model="dialogForm.user_phone"
              clearable
              :disabled="dialogType == 1"
            ></el-input>
          </el-form-item>

          <el-form-item label="性别: ">
            <el-select
              v-model="dialogForm.sex"
              placeholder="请选择"
              style="width: 100%;"
              clearable
            >
              <el-option
                v-for="item in sexList"
                :label="item.name"
                :value="item.name"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="生日">
            <el-input v-model="dialogForm.birthday" clearable></el-input>
          </el-form-item>
          <!-- <el-form-item label="会员卡有效期">
            <el-input v-model="dialogForm.categoryName" clearable></el-input>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input
              type="textarea"
              clearable
              v-model="dialogForm.msg"
            ></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="dialogFormVisible = false">取 消</el-button>
          <el-button class="addBtn" type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 充值消费会员表单 -->
      <el-dialog
        :title="moneyInfo.type === 0 ? '会员充值' : '会员消费'"
        :visible.sync="dialogMoneyVisible"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="moneyInfo" label-width="80px">
          <el-form-item label="卡号">
            <el-input :value="dialogForm.vip_number" disabled></el-input>
          </el-form-item>
          <el-form-item label="会员姓名">
            <el-input :value="dialogForm.user_name" disabled></el-input>
          </el-form-item>
          <el-form-item :label="moneyInfo.type === 0 ? '充值金额' : '消费金额'">
            <el-input
              v-model="moneyInfo.money"
              clearable
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              clearable
              v-model="moneyInfo.message"
            ></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
          <el-button class="cancelBtn" @click="dialogMoneyVisible = false">取 消</el-button>
          <el-button class="addBtn" type="primary" @click="handleUpdateMoney">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import ElTable from "@/components/elTable/index.vue";
import listHeader from "./listHeader";
import filterQueryFormItems from "./filterQueryFormItems";
import Percentage from "@/components/filters/Percentage.vue";
import FillingStatus from "@/components/filters/FillingStatus.vue";
import KindTab from "@/components/tab/kindTab/index.vue";
import StoreToString from "@/components/filters/storeToString.vue";
import {
  selectVipCard,
  addVipCard,
  updateVipCard,
  deleteVipCard,
  updateMoney,
} from "@/services/mall/vipCard";
import { addVipcardRecoder } from "@/services/mall/vipcardRecode";
import { mapState } from "vuex";
export default {
  components: {
    ElTable,
    Percentage,
    FillingStatus,
    KindTab,
    StoreToString,
  },
  async created() {
    this.getListData();
  },
  data() {
    return {
      sexList: [{ name: "男" }, { name: "女" }],
      vipCardList: [
        // { id: 1, name: "储值卡" },
        { id: 2, name: "积分卡" },
        // { id: 3, name: "次卡" },
      ],
      loading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
      },
      /* 表格数据 */
      tableData: [],
      /* 弹窗 */
      dialogName: "",
      dialogType: "",
      dialogForm: {},
      dialogFormVisible: false,
      dialogMoneyVisible: false,

      moneyInfo: {
        // type: "", //0充值 1消费
        // money: "",
        // message: "",
      },
    };
  },
  computed: {
    filterQueryFormItems,
    listHeader,
    ...mapState(["userInfo", "filterStoreList"]),
  },
  watch: {},
  methods: {
    async getListData() {
      let listQuery = JSON.parse(JSON.stringify(this.listQuery));
      listQuery.store_id =
        listQuery.store_id?.join(",") || this.userInfo.store_id;
      this.loading = true;
      const { data, code, count } = await selectVipCard(listQuery);
      if (code === 200) {
        this.tableData = data;
        this.listQuery.total = count;
        setTimeout(() => {
          this.loading = false;
        }, 600);
      }
    },

    /* 搜索按钮 */
    async search(listQuery) {
      console.log("listQuery", listQuery);
      this.listQuery = listQuery;
      this.getListData();
    },

    /* 分页 */
    handleCurrentChange(e) {
      console.log(e);
      this.listQuery.pageNumber = e;
      this.getListData();
    },
    handleSizeChange(e) {
      console.log(e);
      this.listQuery.pageSize = e;
      this.getListData();
    },

    /* 创建会员弹窗 */
    handleAdd(type) {
      this.dialogFormVisible = true;
      this.dialogType = 0;
      this.dialogForm = {};
    },

    /* 修改，充值，消费 */
    handleEdit(row, index) {
      if (index === 1) {
        this.dialogFormVisible = true;
        this.dialogType = 1; //编辑会员
      }
      this.moneyInfo = {};
      if (index === 2) {
        this.dialogMoneyVisible = true;
        this.moneyInfo.type = 0;
      }
      if (index === 3) {
        this.dialogMoneyVisible = true;
        this.moneyInfo.type = 1;
      }
      this.dialogForm = JSON.parse(JSON.stringify(row));
    },

    /* 保存 */
    save() {
      //必填字段校验
      if (
        !this.dialogForm.user_phone ||
        !this.dialogForm.user_name ||
        !this.dialogForm.type ||
        !this.dialogForm.store_id
      ) {
        this.$message.error("请填写必填字段");
        return;
      }
      /* 账号格式校验 */
      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.dialogForm.user_phone)) {
        this.$message.error("手机号格式错误");
        return;
      }
      if (this.dialogType == 0) {
        addVipCard({
          ...this.dialogForm,
          create_user: `后台账号${this.userInfo.sup_username}(ID:${this.userInfo.id})创建`,
        }).then((res) => {
          if (res.code === 200) {
            this.listQuery.pageNumber = 1;
            this.getListData();
            this.$message({
              type: "success",
              message: "创建成功!",
            });
            this.dialogFormVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }
      if (this.dialogType == 1) {
        updateVipCard(this.dialogForm).then((res) => {
          if (res.code === 200) {
            this.getListData();
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.dialogFormVisible = false;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
        });
      }
    },

    /* 充值与消费 */
    handleUpdateMoney() {
      if (!this.moneyInfo.money || this.moneyInfo.money == 0) {
        this.$message.error("请填写正确的金额");
        return;
      }
      updateMoney({
        ...this.dialogForm,
        money: this.moneyInfo.money,
        type: this.moneyInfo.type,
      }).then((res) => {
        if (res.code === 200) {
          this.listQuery.pageNumber = 1;
          this.getListData();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.dialogMoneyVisible = false;
          /* 交易记录留存  this.moneyInfo.type == 1 消费*/
          addVipcardRecoder({
            ...this.dialogForm,
            cancel_id: `${this.userInfo.sup_username}(后台账号ID:${this.userInfo.id})`,
            amount:
              this.moneyInfo.type == 1
                ? -this.moneyInfo.money
                : this.moneyInfo.money,
            message: this.moneyInfo.message,
          }).then((res) => {
            console.log("交易记录留存", res);
          });
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },

    /* 删除 */
    handleDelete(row) {
      this.$confirm(`确定删除手机号为"${row.user_phone}"的会员卡吗?`, "禁用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        deleteVipCard({ id: row.id, vip_number: row.vip_number }).then(
          (res) => {
            if (res.code === 200) {
              this.getListData();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: res.msg,
              });
            }
          }
        );
      });
    },
  },
};
</script>

<style scoped lang="less">
.diseaseList {
  width: 100%;
}
.selectForm {
  background: #fff;
  // max-width: 1405px;
  padding: 10px 0;
  margin: 10px auto;
}
.tableList {
  border-radius: 6px;
  background: #fff;
  // max-width: 1405px;
  margin: 10px auto;
}

.operation {
  a {
    margin-right: 10px;
  }
}
</style>
