export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 70 },
    // { label: "门店ID", prop: "store_id", width: 100 },
        { label: "卡种", prop: "type", slots: "type", width: 120 },
    { label: "所属门店", prop: "store_id", width: 180, slots: "store_id" },

    { label: "卡号", prop: "vip_number", width: 190 },
    { label: "昵称", prop: "user_name", width: 190 },
    { label: "手机号", prop: "user_phone", width: 190 },
    // { label: "性别", prop: "sex", width: 80 },
    // { label: "生日", prop: "birthday", width: 140 },
    { label: "余额", prop: "money", width: 190 },
    { label: "备注", prop: "msg", width: 200 },
    // {
    //   label: "开卡时间",
    //   prop: "create_time",
    //   width: 180,
    //   // slots: "state",
    // },
    // { label: "开卡人", prop: "create_user", width: 300 },
    {
      label: "操作",
      prop: "operation",
      width: 200,
      slots: "operation",
    },
  ];
}
