import axios from "axios";
import { request } from "@/utils/request";

/* 会员模块接口 */

/**
 * 查询
 * @param {*} data
 * @returns
 */
export function selectVipCard(data) {
  return request({
    url: `/admin/vipCard/select`,
    method: "POST",
    data,
  });
}

/**
 * 新增
 * @param {*} data
 * @returns
 */
export function addVipCard(data) {
  return request({
    url: `/admin/vipCard/add`,
    method: "POST",
    data,
  });
}

/**
 * 修改
 * @param {*} data
 * @returns
 */
export function updateVipCard(data) {
  return request({
    url: `/admin/vipCard/update`,
    method: "POST",
    data,
  });
}



/**
 * 充值与消费
 * @param {*} data
 * @returns
 */
export function updateMoney(data) {
  return request({
    url: `/admin/vipCard/updateMoney`,
    method: "POST",
    data,
  });
}


/**
 * 删除
 * @param {*} data
 * @returns
 */
export function deleteVipCard(data) {
  return request({
    url: `/admin/vipCard/delete`,
    method: "POST",
    data,
  });
}

